import * as React from "react";
import {ChakraProvider, Center, Heading, Button, VStack} from "@chakra-ui/react";
import {homeLink} from "../helper/links";
import theme from "../styles/theme";
import {helmetText} from "../helper/text";
import HelmetHeader from "../components/helmetHeader";

export default function Error404({ location }) {
    const url = location.href ? location.href : '';

    return (
        <>
            <HelmetHeader
                title={helmetText.error404Title}
                blueTheme={true}
            />
            <ChakraProvider theme={theme} >
                <Center
                  width="100vw"
                  height="100vh"
                  backgroundColor="brand.500"
                >
                      <VStack
                          rounded="lg"
                          backgroundColor="gray.100"
                          spacing={8}
                          padding={8}
                      >
                          <Heading
                              fontSize="xl"
                              color="black"
                          >
                              {url.includes("tools") ? "Whoops! Unfortunately, App Clips can only be opened on an iPhone running iOS 14 or later." : "404 - Sorry, that page does not exist."}
                          </Heading>
                          <Button
                              as="a"
                              href={homeLink}
                              backgroundColor="brand.500"
                              color="white"
                          >
                              Go home
                          </Button>
                      </VStack>
                  </Center>
            </ChakraProvider>
        </>
    );
}